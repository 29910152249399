<script lang="ts">
	export let size = 24;
	export let color = '';
	export let viewBoxWidth = 0;
	export let viewBoxHeight = 0;
	export let css = '';
	export let icon: string | string[];

	let conditionalAttributes: {
		[id: string]: string;
	} = {};
	conditionalAttributes.width = `${size}px`;
	conditionalAttributes.height = `${size}px`;

	if (viewBoxWidth > 0 && viewBoxHeight > 0) {
		conditionalAttributes.viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
	} else {
		conditionalAttributes.viewBox = `0 0 ${size} ${size}`;
	}

	if (color !== '') {
		conditionalAttributes.fill = color;
	} else {
		conditionalAttributes.stroke = 'currentColor';
	}
</script>

<svg xmlns="http://www.w3.org/2000/svg" class={`flex ${css}`} {...conditionalAttributes}>
	{#if typeof icon === 'string'}
		<path d={icon} />
	{/if}
	{#if Array.isArray(icon)}
		<g>
			{#each icon as i}
				<path d={i} />
			{/each}
		</g>
	{/if}
</svg>
